.StepHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 1rem;
  color: rgba(189, 189, 189, 1);

  height: calc(8vh + 1rem);
}

.StepHeader__title {
  font-family: var(--ff-poppins);
  margin: 1rem;
  margin-right: auto;
  font-size: 1rem;
}

.StepHeader::before {
  content: url('../files/stepIcon.svg');
  margin: 1rem;
}

.PracticeStep__header {
  display: flex;
  justify-content: flex-end;
  list-style-type: none;

  margin: 1.3rem;
}

.PracticeStep__header > * {
  margin: 0rem 0.8rem;
}

.noAlternative {
  height: 222px;
  width: 848px;
  left: 410px;
  top: 1589px;
  border: 1px solid #e0e0e0;
  box-shadow: 0px 2px 2px 0px #0000001a;
}
.noAlternative-text {
  color: #333333;
  font-family: 'Poppins';
  font-size: 18px;
  line-height: 89%;
  text-align: center;
  margin-top: 80px;
}
.noAlternative-btn {
  margin-left: 45%;
  margin-top: 10px;
}
.Duration {
  background: #e0e0e0;
  height: 40.42262649536133px;
  width: 189.07357788085938px;
  margin-left: 44%;
  border-radius: 0px;
}

.Question-label-input {
  height: 108px;
  width: 675px;
  border-radius: 0px;
  background: #f2f2f280;
}

/* StepView */
.StepViewer__courseTitle {
  font-family: 'poppins';
  font-weight: 700;
  color: #333333;
}
.StepViewer__duration span:first-child::before {
  content: url('../files/clock.svg');
}
.StepViewer__duration {
  display: flex;
  margin-top: -17px;
  font-size: x-small;
  font-family: 'poppins';
}
.StepViewer__duration span:first-child {
  margin-right: 10px;
}
.StepViewerBody__title span:first-child::before {
  content: url('../files/green-validate.svg');
}
.StepViewerBody__title span:first-child {
  margin-right: 10px;
}
.StepViewerBody__title {
  display: flex;
}
.QCMViewer__img {
  background: url('../files/studentMcqHome.png');
  height: 378px;
  width: 450px;
  margin-left: 72px;
}
.QCMViewer button {
  margin-left: 370px;
}

/* QCM */
.QCM__score {
  margin: -74px auto auto auto;
  height: 72.331635px;
  width: 137px;
  border-radius: 1px;
  background: #f5f5f7;
  padding-left: 28px;
}

.QCM__score span,
.QCMEndModal__score span {
  height: 96px;
  width: 52px;
  border-radius: 0px;
  font-family: 'poppins';
  font-weight: 900;
  color: #2d9cdb;
  line-height: 89px;
  font-size: x-large;
}
.AskQuestion__title {
  font-family: 'poppins';
  font-size: 20px;
  font-weight: 600;
  line-height: 39px;
  letter-spacing: 0em;
  text-align: left;
  color: #2d9cdb;
}
.AskQuestion__label,
.AnswerNonSelected,
.AnswerSelected,
.NotGoodAnswer,
.GoodAnswer {
  height: 57px;
  width: 550px;
  font-family: 'poppins';
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: 24px;
  letter-spacing: 0px;
  text-align: left;
  color: #333333;
}
.AnswerNonSelected span,
.NotGoodAnswer span {
  content: url('../files/answerNonSelectedVector.svg');
}
.AnswerSelected span,
.GoodAnswer span {
  content: url('../files/answerSelectedVector.svg');
}
.AnswerSelected span,
.AnswerNonSelected span,
.NotGoodAnswer span,
.GoodAnswer span {
  border-radius: 50%;
  background: #fff;
  border: 2px solid #fff;
  padding: 5px;
  margin: 10px 21px -12px 32px;
}
.AnswerNonSelected {
  background: #2d9cdb0d;
}
.AnswerSelected {
  background: #2d9cdb54;
}
.NotGoodAnswer {
  background: #eb57574d;
}
.GoodAnswer {
  background: #27ae60;
}
.QCM__oneQuestion button {
  margin-left: 448px;
}

/* QCM end modal */
.ButtonEndMCQModal {
  height: 624px;
}
.ButtonEndMCQModal__title {
  margin-left: auto;
  color: #026599;
  font-weight: 600;
  font-family: 'poppins';
}
.ButtonEndMCQModal__body {
  margin-left: 206px;
  color: #333333;
  font-weight: 400;
  font-family: 'poppins';
}
.ButtonEndMCQModal__footer,
.ButtonEndMCQModal__header {
  margin: auto;
}
.QCMEndModal__conclusion {
  margin-left: -192px;
  text-align: center;
}
