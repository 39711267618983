/*Courses */
.Course__Item {
  display: flex;
  align-items: center;
  justify-content: space-between;

  margin: 1.5rem 0;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.2);
}

.Course__Item:hover {
  background: rgba(224, 224, 224, 0.2);
  cursor: pointer;
}

.Course__image {
  max-width: 228px;
}

.Course__section {
  flex-grow: 1;
  margin: 0 0.5rem;
}

.Course__title {
  font-family: var(--ff-poppins);
  letter-spacing: 0.1rem;
  font-weight: 600;
  color: rgba(51, 51, 51, 1);
}

/* STUDENT COURSE */
.Course__header {
  display: inline-flex;
  background: #2d9cdb1a;
  height: auto;
  width: 830px;
  border-radius: 0px;
  box-shadow: 0px 2px 4px 0px rgb(0 0 0 / 20%);
  padding: 8px 50px 5px;
  margin-left: -55px;
  margin-bottom: 30px;
}
.Course__title {
  font-family: 'poppins';
  font-size: 15px;
  font-style: normal;
  font-weight: 700;
  letter-spacing: 0em;
  color: #333333;
}
.Course__description {
  border-radius: Opx;
  font-family: 'poppins';
}
.Course__hourlyMass {
  font-weight: 600;
  margin: auto auto auto 50px;
}
.Course__hourlyMass span::after {
  content: url('../files/clock.svg');
}
.Course__hourlyMass span {
  margin-right: 10px;
}
.CourseChapter__puce::after {
  content: url('../files/green-validate.svg');
}
.CourseChapter__puce {
  margin-right: 10px;
}
.Course__chapter span {
  font-weight: 700;
  border-bottom: 0, 5px solid #b4b4b4;
}
.ChapterSteps__one,
.Course__noChapterOrStep {
  height: 64px;
  width: 600px;
  border-radius: 0px;
  background: #f9f9f9;
  font-family: 'poppins';
  padding-left: 30px;
  padding-top: 10px;
}
.CourseChapter__trait {
  width: 600px;
}
