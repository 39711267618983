.Input {
  display: inline-flex;
  flex-direction: column;
  /* align-items: center; */
  margin: 0;
}

.Input.success input,
.Input.success select {
  outline: none;
  border: 1px solid #27ae60 !important;
}

.Input.error input,
.Input.error select {
  outline: none;
  border: 1px solid #eb5757 !important;
  background-color: rgba(235, 87, 87, 0.06);
}

.Input.error input:focus,
.Input.error select:focus {
  outline: none;
  border: 1px solid #eb5757;
}

small {
  color: #eb5757;
  margin: 0;
}

.Input input,
select {
  box-sizing: border-box;

  width: 100%;

  border: 1px solid #d4d4d4;
  background: #ffffff;

  padding: 10px 20px 10px 20px;

  font-style: normal;
  font-weight: 300;
  font-size: 1rem;
  line-height: 21px;

  margin: 0;
}
