.History {
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
}
.History__descr {
  max-width: 800px;
}
.History__text {
  margin-right: 20px;
}
.History__box {
  min-width: 500px;
  width: 500px;
  position: relative;
}
.History__caption {
  font-family: Poppins;
  font-style: italic;
  font-weight: normal;
  font-size: 12px;
  line-height: 15px;
  color: #4d533c;
  text-align: center;
}
.History__boxcontent {
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
}
.History__fdgImg {
  width: 100%;
  margin-bottom: 10px;
}
.History__box--blur {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  filter: blur(10px);
  z-index: -1;
  border: 5px solid;
}
.History__boxcontent {
  padding: 20px;
  background: white;
}

@media screen and (max-width: 1200px) {
  .History {
    flex-flow: column nowrap;
    margin: 0px;
    width: 100%;
  }
  .History__text {
    margin-bottom: 20px;
  }
  .History__box {
    width: 100%;
    min-width: 0px;
    max-width: 500px;
  }
}

@media screen and (max-width: 768px) {
  .History__text--mobile {
    text-align: center;
  }
}
