:root {
  /* font family*/
  --ff-poppins: Poppins;
  font-size: 16px;
  /* color */
  --color--red-dark: rgba(223, 81, 81, 1);
  --color--blue-dark: rgba(2, 101, 153, 1);
}

html {
  box-sizing: border-box;
}

*,
*:before,
*:after {
  box-sizing: inherit;
  margin: 0;
  padding: 0;
}

/* Utils */
.ToolIcon {
  margin: 0 0.5rem;
  cursor: pointer;
}

.ToolIcon {
  filter: brightness(1.5);
}

.text--red {
  font-family: var(--ff-poppins);
  font-size: 1.2rem;
  font-weight: 700;
  color: var(--color--red-dark);
}

.text--blue {
  font-family: var(--ff-poppins);
  font-size: 1.2rem;
  font-weight: 700;
  color: var(--color--blue-dark);
}

.text--big-size {
  font-size: 2rem;
}

.text--center {
  text-align: center;
}

.PDFList__with-action {
  display: flex;
  flex-direction: column;
}

.PDFList__without-action {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.PDFList-item {
  display: flex;
  align-items: center;
  justify-content: space-between;

  box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.1);
  margin: 0.2rem 3rem;
  padding: 0rem 3rem 0rem 1rem;
}

.PDFList-item:hover {
  background: rgba(224, 224, 224, 0.2);
}

.PDF__name:hover {
  cursor: pointer;
  background: rgba(224, 224, 224, 0.2);
}

.PDF__name {
  display: flex;
  align-items: center;
  width: fit-content;

  font-family: var(--ff-poppins);
  font-size: 1rem;
  border: none;
  background-color: transparent;
}

.PDF__name::before {
  content: url('./files/pdfFile.svg');
  scale: 0.4;
}

.PDFViewer__container {
  display: flex;
  justify-content: center;
  user-select: none;
}

.PDF__Page > canvas {
  max-width: 100%;
  min-width: 70vmin;
  max-height: auto !important ;
  min-height: 768px;
}

.PDFViewer {
  position: relative;
}

.PDFViewer__controls {
  position: absolute;
  bottom: 0%;
  left: 50%;
  transform: translate(-50%, -50%);
  margin: 20px;
}

.Modal {
  position: absolute;
  top: 55%;
  left: 50%;
  right: auto;
  bottom: auto;
  margin-right: -50%;
  transform: translate(-50%, -50%);
  width: min-content;
}

/*
 * Make sure options are not display
 * when user try to print the pdf
*/
@media print {
  .PDFViewer__container {
    display: none;
  }
}
/*
 * Styles shared by Menus : Navbars and/or Sidebars
 */
#public-page-wrap {
  overflow: auto; /* Prevent sidebar from showing a scrollbar on page */
}
.private-content {
  margin-top: 90px;
  padding: 30px;
  padding-bottom: 0;
}

@media screen and (max-width: 768px) {
  .private-content {
    padding: 1em;
  }
}

.sidebar-title {
  font-family: var(--ff-poppins);
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 20px;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: rgba(224, 224, 224, 0.62);
  margin-top: 50px;
}

.menu-item span {
  margin-left: 10px;
  /* font: font-weight font-variant font-size font-style */
  font: normal normal 14px var(--ff-poppins);
  color: #ffffff;
}

.menu-item {
  padding: 10px;
  margin-left: auto;
  margin-right: auto;
  display: block;
  outline: none;
}

.menu-item-disabled {
  cursor: default;
}

.menu-item-disabled:hover {
  background-color: transparent !important;
}

.sub-menu-item {
  padding: 10px;
  margin-left: 25px;
  margin-right: auto;
  display: block;
  outline: none;
}

.sidebar-footer {
  border-top: 1px solid rgba(255, 255, 255, 0.16);
  padding-top: 20px;
  width: 100%;
  margin-top: 60px;
}
.sidebar-footer h3 {
  font-family: var(--ff-poppins);
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 20px;
  color: #ffffff;
}
.sidebar-footer p {
  font-family: var(--ff-poppins);
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  color: #9ba4b0;
}

/*
 * Private/Public MobileSideBar
 */
#public-outer-container .bm-menu {
  background: #288cc6;
}
#public-outer-container .bm-item {
  color: #ffffff;
  padding: 10px;
}
#public-outer-container a.bm-item:hover {
  color: white;
  background: rgba(86, 204, 242, 0.4);
  text-decoration: none;
}
#public-outer-container .bm-burger-button {
  right: 30px;
  top: 30px;
}
#private-outer-container .student,
#private-outer-container .student .bm-menu {
  background: #026599;
}
#private-outer-container .student,
#private-outer-container .student .bm-item {
  color: #d1d1d1;
}
#private-outer-container .student a:hover,
#private-outer-container .student a.bm-item:hover {
  color: #ffffff;
  background: #2d9cdb;
  text-decoration: none;
}
#private-outer-container .professor,
#private-outer-container .professor .bm-menu {
  background: #a73333;
}
#private-outer-container .student,
#private-outer-container .student .bm-item {
  color: #ffffff;
}
#private-outer-container .professor a:hover,
#private-outer-container .professor a.bm-item:hover {
  color: #ffffff;
  background: #a73333;
  text-decoration: none;
}
#private-outer-container .admin .bm-menu,
#private-outer-container .admin {
  background: #333333;
}
#private-outer-container .admin .bm-item,
#private-outer-container .admin {
  color: #ffffff;
}
#private-outer-container .admin a:hover,
#private-outer-container .admin a.bm-item:hover {
  color: #ffffff;
  background: #828282;
  text-decoration: none;
}
#private-outer-container .bm-burger-button {
  left: 30px;
  top: 30px;
}

.mobile-private-sidebar-user {
  width: 80%;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
}
.mobile-private-sidebar-user-name {
  font-family: var(--ff-poppins);
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 21px;
  text-align: right;
  letter-spacing: 0.01em;
  color: #ffffff;
}
.mobile-private-sidebar-user-userType {
  font-family: var(--ff-poppins);
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 18px;
  text-align: right;
  letter-spacing: 0.01em;
  color: #a6acbe;
}
.mobile-private-sidebar-logout {
  width: 80%;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  margin-top: -12px;
}
.mobile-private-sidebar-logout span {
  margin-left: 10px;
  font-family: var(--ff-poppins);
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 0.01em;
  color: #ffffff;
}

/*
 * Desktop Sidebar
 */
.desktop-sidebar {
  width: 250px;
  position: fixed;
  top: 90px;
  bottom: 0;
  padding-left: 20px;
  padding-right: 20px;
  z-index: 1000;
  overflow-y: auto;
}

.student-menu-active,
.student-background a:hover {
  background: #2d9cdb;
  text-decoration: none;
}
.admin-background a:hover,
.admin-menu-active {
  background: #828282;
  text-decoration: none;
}
.professor-menu-active,
.professor-background a:hover {
  background: #a73333;
  text-decoration: none;
}
.admin .menu-active {
  background: #828282;
  text-decoration: none;
}
.student .menu-active {
  background: #2d9cdb;
  text-decoration: none;
}
.student-background {
  background: #026599;
}
.professor .menu-active {
  background: #a73333;
  text-decoration: none;
}
.professor-background {
  background: #a73333;
}
.admin-background {
  background: #333333;
}
.desktop-sidebar .desktop-sidebar-space {
  font-family: var(--ff-poppins);
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 20px;
  letter-spacing: 1px;
  color: #ffffff;
}
.desktop-sidebar-userType {
  font-family: var(--ff-poppins);
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  color: #ffffff;
}
.desktop-sidebar-title {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

/*
 * Dashboard Content
 */
.main-content-offset {
  padding-left: 250px;
}

/*
 *  Buttons
 */
.btn-admin {
  font-family: var(--ff-poppins);
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  padding: 10px 20px 10px 20px;
  background: white;
  white-space: nowrap;
  text-align: center;
  border: none;
}
.btn-admin.blue {
  color: #2d9cdb;
  border: 1px solid #2d9cdb;
}
.btn-admin.blue-off {
  color: #2d9cdb;
  background-color: transparent;
  border: 1px solid #2d9cdb;
}
.btn-admin.blue-off:hover {
  color: #fff;
  background-color: #2d9cdb;
  border: 1px solid #2d9cdb;
}
.btn-admin.blue:hover svg {
  fill: #ffffff; /* changes the color to red */
}
.btn-admin.green {
  color: #219653;
  border: 1px solid #219653;
}
.btn-admin.black {
  color: #333333;
  border: 1px solid #333333;
}
.btn-admin.red {
  color: #eb5757;
  border: 1px solid #eb5757;
}
.btn-admin.blue:hover {
  background: #2d9cdb;
}
.btn-admin.green:hover {
  background: #219653;
}
.btn-admin.black:hover {
  background: #333333;
}
.btn-admin.red:hover {
  background: #eb5757;
}
.btn-admin:hover {
  color: white;
}
.btn-admin:disabled,
.btn-admin:disabled:hover {
  border: 1px solid #999999;
  background-color: #cccccc;
  color: #666666;
  cursor: not-allowed;
}
button:disabled,
button:disabled:hover {
  border: 1px solid #999999;
  background-color: #cccccc;
  color: #666666;
  cursor: not-allowed;
}
.btn-admin-link {
  font-family: var(--ff-poppins);
  font-style: normal;
  font-weight: 300;
  font-size: 13px;
  line-height: 19px;
  padding: 0px;
  border: none;
  background: transparent;
}

.btn-admin-link.hover:hover {
  outline: none;
  text-decoration: underline;
}

.btn-admin-link:hover {
  outline: none;
  box-shadow: none;
  text-decoration: none;
}
.btn-admin-link.blue {
  color: #2d9cdb;
}
.btn-admin-link.black {
  color: #333333;
}
.btn-admin-link.red {
  color: #eb5757;
}
.btn-admin-link.green {
  color: #219653;
}

/* Button Public */
.btn-public {
  font-family: var(--ff-poppins);
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  padding: 6px 50px 6px 50px;
  background-color: transparent;
  border-radius: 0.25rem;
  max-width: 200px;
  text-align: center;
  margin-bottom: 10px;
}
.btn-public.centered {
  margin: 0 auto;
  display: block;
}
.btn-public.red {
  color: #f53855;
  border: 1px solid #f53855;
}
.btn-public.red:hover {
  background: #f53855;
}
.btn-public.blue {
  color: #2d9cdb;
  border: 1px solid #2d9cdb;
}
.btn-public.blue:hover {
  background: #2d9cdb;
}
.btn-public:hover {
  outline: none;
  box-shadow: none;
  text-decoration: none;
  color: white;
}

/*
 * Admin Dashboard
 */
.admin-modal .modal-dialog {
  max-width: 700px;
}
.admin-modal .modal-title {
  font-family: var(--ff-poppins);
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 30px;
  letter-spacing: 1px;
  color: #333333;
}
.admin-modal .modal-body {
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  font-family: var(--ff-poppins);
  font-style: normal;
  font-weight: 300;
  font-size: 10px;
  line-height: 25px;
  text-align: center;
  color: #333333;
}

/*
 * Admin Tabs
 */
.admin-tabs ul.react-tabs__tab-list {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-evenly;
  align-items: flex-end;
  list-style-type: none;
}
.admin-tabs .react-tabs__tab {
  font-family: var(--ff-poppins);
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  letter-spacing: 0.01em;
  color: #333333;
  border-bottom: 0.5px solid #828282;
  width: 100%;
  text-align: center;
  padding-bottom: 15px;
  cursor: pointer;
}
.admin-tabs .react-tabs__tab--selected {
  color: #000000;
  border-bottom: 3px solid #df5151;
}

/*
 * Admin Tools
 */

.admin-tools-filters {
  display: flex;
  flex-flow: row nowrap;
}
.admin-tools-filters select {
  width: 100%;
  height: 50px;
  margin-bottom: 10px;
  background: #ffffff;
  box-shadow: 10px 10px 99px rgba(211, 211, 211, 0.7);
  font-family: var(--ff-poppins);
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: #333333;
  text-align: center;
  text-align-last: center;
  border: none;
  margin-right: 0.8rem;
}
.admin-tools-radio {
  width: 100%;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-evenly;
  align-self: center;
}
.admin-tools-radio label {
  display: block;
  position: relative;
  padding-left: 35px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  font-family: var(--ff-poppins);
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #828282;
}
.admin-tools-radio .active {
  color: #eb5757;
}
/* Hide the browser's default radio button */
.admin-tools-radio label input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}
/* Create a custom radio button */
.admin-tools-radio label .checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 20px;
  width: 20px;
  border-radius: 50%;
  border-style: solid;
  border-color: #a73333;
}
/* On mouse-over, add background color */
.admin-tools-radio label:hover input ~ .checkmark {
  background-color: #a73333;
}
/* Create the indicator (the dot/circle - hidden when not checked) */
.admin-tools-radio label .checkmark:after {
  content: '';
  position: absolute;
  display: none;
}
/* Show the indicator (dot/circle) when checked */
.admin-tools-radio label input:checked ~ .checkmark:after {
  display: block;
}
/* Style the indicator (dot/circle) */
.admin-tools-radio label .checkmark:after {
  top: 3px;
  left: 3px;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: #a73333;
}

/*
 * Admin Table Cells
 */
.admin-cell,
.admin-cell h4 {
  display: flex;

  font-family: var(--ff-poppins);
  font-style: normal;
  font-size: 14px;
  color: #333333;
  padding: 0px 10px 0px 10px;
}
.admin-cell.column-dir {
  flex-direction: column;
}
.admin-cell.row-dir {
  flex-flow: row wrap;
}
.admin-cell-actions .separator {
  border-left: 1px solid #333333;
  margin-left: 5px;
  margin-right: 5px;
  height: 10px;
}
.admin-cell p {
  margin-bottom: 0px;
}
.admin-cell img {
  width: 100%;
}
.admin-cell ul {
  text-align: start;
  justify-content: flex-start;
}

/*
 * Common Admin Table Style
 */
table {
  padding-left: 10px;
}

.table-pagination {
  position: sticky;
  bottom: 0;

  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
}

.table-pagination:hover {
  background-color: rgba(249, 248, 248, 0.5);
}

.table-pagination .pagination-buttons {
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: center;
}

.table-pagination .pagination-info {
  display: flex;
  flex-flow: column wrap;
  justify-content: center;
  align-items: center;
}

.table-pagination button {
  margin-right: 1px;
}

.table-active-row {
  background-color: rgb(194, 184, 95) !important;
}

.admin-table th {
  position: sticky;
  top: 90px;

  background: #ffffff;
}

.admin-table td {
  padding-left: 10px;
}

.admin-table .publication-date {
  font-family: var(--ff-poppins);
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 24px;
  color: #333333;
}

.admin-table tbody tr:nth-child(odd) {
  background: #f9f8f8;
}

.admin-table table thead tr th {
  font-family: var(--ff-poppins);
  font-style: normal;
  font-size: 14px;
  color: #000000;
  padding: 10px;
  background-color: #f5f5f5;
}

/*
 * Student Dashboard
 */
.student-paragraph {
  font-family: var(--ff-poppins);
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 24px;
  color: #333333;
}
.student-title-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: baseline;
}
.student-title {
  font-family: var(--ff-poppins);
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 24px;
  color: #333333;
  padding: 10px;
}
.student-paragraph a {
  color: #2d9cdb;
}
.student-bullet-list {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin-left: 20px;
}
.student-subtitle {
  font-family: var(--ff-poppins);
  font-style: normal;
  font-weight: bold;
  font-size: 17px;
  line-height: 10px;
  color: black;
  /*
  display: list-item;
  */
}
.info-box {
  display: flex;
  align-items: center;
  background-color: #f0f0f0; /* couleur de fond à ajuster */
  border-radius: 10px; /* border radius pour l'effet 'rounded' */
  padding: 10px;
  margin: 10px 0;
}

.info-box .info-text {
  margin-left: 10px;
}

/*
 * Footer
 */
.footer {
  margin-top: 50px;
}
.footer-padding {
  padding-top: 30px;
  padding-bottom: 30px;
}
.footer-firstline {
  background: #2d9cdb;
}
.footer-firstline h2 {
  font-family: var(--ff-poppins);
  font-style: normal;
  font-weight: normal;
  font-size: 34px;
  line-height: 51px;
  color: #ffffff;
  text-transform: capitalize;
  font-weight: bold;
  margin-bottom: 10px;
}
.footer-firstline p {
  font-family: var(--ff-poppins);
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 30px;
  color: #ffffff;
}
.footer-firstline form input[type='text'] {
  background: rgba(255, 253, 253, 0.28);
  border: none;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  margin-right: 0px;
  font-size: 16px;
  width: 50%;
  font-family: var(--ff-poppins);
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 21px;
  color: #e5e5e5;
  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -ms-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
  -webkit-border-radius: 5px 5px 5px 5px;
  border-radius: 5px 5px 5px 5px;
}
.footer-firstline form input[type='submit'] {
  background-color: #026599;
  border: none;
  color: white;
  padding: 15px 23px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  text-transform: uppercase;
  font-size: 13px;
  font-family: var(--ff-poppins);
  font-style: normal;
  line-height: 24px;
  align-items: center;
  -webkit-box-shadow: 0 10px 30px 0 rgba(95, 186, 233, 0.4);
  box-shadow: 0 10px 30px 0 rgba(95, 186, 233, 0.4);
  -webkit-border-radius: 5px 5px 5px 5px;
  border-radius: 5px 5px 5px 5px;
  margin: 5px 20px 0px 20px;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
.footer-firstline .footer-newsletter-error {
  color: #f53855;
}
.footer-firstline .footer-mail-parent {
  position: relative;
  top: 0;
  left: 0;
}
.footer-firstline .footer-mail-background {
  margin-left: auto;
  margin-right: auto;
  display: block;
  height: 295px;
  opacity: 0.2;
  position: relative;
  top: 0;
  left: 0;
}
.footer-firstline .footer-mail-principal {
  height: 150px;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
}
.footer-secondline img {
  width: 150px;
  margin-bottom: 30px;
}
.footer-secondline .footer-text {
  font-family: var(--ff-poppins);
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 25px;
  color: #4f5665;
}
.footer-secondline .footer-contact {
  font-family: var(--ff-poppins);
  font-style: normal;
  font-weight: bold;
  font-size: 13px;
  line-height: 25px;
  color: #4f5665;
}
.footer-socials {
  display: flex;
  flex-flow: row wrap;
}
.footer-secondline .footer-copyright {
  font-family: var(--ff-poppins);
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 30px;
  text-align: center;
  color: #afb5c0;
}
.footer-secondline .footer-other-pages {
  margin-top: 30px;
}
.footer-secondline .footer-other-pages h3 {
  font-family: var(--ff-poppins);
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 30px;
  color: #0b132a;
  margin-bottom: 15px;
}
.footer-secondline .footer-other-pages a {
  font-family: var(--ff-poppins);
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 30px;
  color: #4f5665;
  display: block;
}

/*
 * Utilities
 */
.do-not-display {
  display: none !important;
}
.mailto-link {
  color: #333333;
  font-weight: bold;
}
.mailto-link:hover {
  color: #000000;
  font-weight: bold;
}
.logo {
  width: 29px;
  display: block;
  margin-left: auto;
  margin-right: auto;
}
.logo-complete {
  width: 80%;
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 30px;
}
.section-padding {
  padding-top: 50px;
  padding-bottom: 50px;
}
.section-gray {
  background: linear-gradient(
    180deg,
    rgba(221, 224, 228, 0.34) -45.04%,
    rgba(221, 221, 221, 0.12) 88.56%
  );
}
.section-pink {
  background: rgba(235, 87, 87, 0.06);
}
.page-sides-padding-default {
  padding-left: 50px;
  padding-right: 50px;
}
.page-sides-padding-mobile {
  padding-left: 10px;
  padding-right: 10px;
}
.section-title {
  text-transform: capitalize;
  font-weight: bold;
  margin-bottom: 40px;
}
.highlight-1 {
  background: rgba(235, 87, 87, 0.06);
}
.bold {
  font-weight: bold;
}
.btn-readmorered {
  font-family: FontAwesome;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 18px;
  color: rgba(255, 82, 93, 0.54);
}
.btn-readmoreblue {
  font-family: FontAwesome;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 18px;
  color: #2d9cdb;
}
.btn-readmoregray {
  font-family: FontAwesome;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 18px;
  color: #bdbdbd;
}
.btn-readmoreredsquarecentered {
  color: #f53855;
  border: 1px solid #f53855;
  padding-left: 50px;
  padding-right: 50px;
  margin: 0 auto;
  display: block;
}
.btn-readmoreredsquare {
  color: #f53855;
  border: 1px solid #f53855;
  padding-left: 50px;
  padding-right: 50px;
}
.btn-readmorebluesquare {
  color: #2d9cdb;
  border: 1px solid #2d9cdb;
  padding-left: 50px;
  padding-right: 50px;
}
.btn-readmorebluesquarecenter {
  color: #2d9cdb;
  border: 1px solid #2d9cdb;
  padding-left: 50px;
  padding-right: 50px;
  margin: 0 auto;
  display: block;
}
.public-page-content {
  margin-top: 90px;
}
.left-30-offset {
  padding-left: 30px;
}

/*
 * Public Page Header
 */
.public-page-header {
  display: flex;
  width: 100%;
  height: 250px;
  position: relative;
}
.public-page-header img {
  object-fit: cover;
  width: 100%;
  filter: brightness(20%);
  -khtml-user-select: none;
  -o-user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  user-select: none;
}
.public-page-header .public-page-header-descr {
  position: absolute;
  bottom: 40px;
  left: 40px;
  margin-right: 40px;
  font-family: var(--ff-poppins);
  font-style: normal;
  display: flex;
  flex-direction: column;
  max-width: 400px;
}
.public-page-header .public-page-header-descr h1 {
  font-weight: 500;
  font-size: 30px;
  line-height: 40px;
  letter-spacing: 1px;
  color: #ffffff;
}
.public-page-header .public-page-header-descr p {
  font-weight: 300;
  font-size: 18px;
  line-height: 20px;
  color: #fffcfc;
}

/*
 * Home
 */
.home-slider {
  width: 100%;
  height: 400px;
}
.home-slider img {
  object-fit: cover;
  width: 100%;
  height: 100%;
}
.home-assets .slick-prev {
  margin-left: 25px;
  z-index: 10;
}
.home-assets .slick-next {
  margin-right: 25px;
}
.light-paragraph {
  font-family: var(--ff-poppins);
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 26px;
  color: rgba(33, 56, 66, 0.6);
}
.home-read-more-collapse .not-open {
  display: block;
  height: 100px !important;
  overflow: hidden;
}
.home-read-more-collapse .button-clicked {
  display: none;
  border: 1px solid;
}

.home-learning-cycle {
  width: 100%;
  padding-top: 30px;
  padding-bottom: 30px;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  color: white;
  background: #eb5757;
  border: 2px solid #f2f2f2;
  box-sizing: border-box;
  font-family: var(--ff-poppins);
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  text-decoration: none;
}

.hashLink:hover {
  text-decoration: none;
  color: white;
}

.home-article-container {
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  width: 100%;
}
.home-article {
  max-width: 340px;
  display: flex;
  flex-flow: column nowrap;
  border: 1px solid rgba(235, 87, 87, 0.5);
  box-sizing: border-box;
  border-radius: 0px;
  margin-right: 10px;
  margin-bottom: 20px;
}
.home-article-img-container {
  display: block;
  margin: auto;
  height: 230px;
  border-bottom: 1px solid rgba(235, 87, 87, 0.5);
  box-sizing: border-box;
  border-radius: 0px;
}
.home-article img {
  height: 100%;
  width: 100%;
  object-fit: cover;
  -webkit-filter: grayscale(100%); /* Safari 6.0 - 9.0 */
  filter: grayscale(100%);
}
.home-article.active img {
  filter: none;
  -webkit-filter: none;
}
.home-article .home-article-text {
  padding: 20px;
}
.home-article.active .home-article-text {
  background: white;
}
.home-article h3 {
  font-family: var(--ff-poppins);
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  display: flex;
  align-items: center;
  color: #bdbdbd;
}
.home-article.active h3 {
  color: #eb5757;
}
.home-article p {
  font-family: var(--ff-poppins);
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 25px;
  color: #bdbdbd;
}
.home-article.active p {
  color: #0b132a;
}
.home-gallery {
  margin-bottom: 30px;
}
.home-events {
  margin-top: 20px;
  background: #2d9cdb;
}
.home-events h2 {
  width: 95%;
  font-family: var(--ff-poppins);
  font-style: normal;
  font-weight: bold;
  font-size: 25px;
  line-height: 37px;
  text-align: center;
  color: #ffffff;
  margin-left: auto;
  margin-right: auto;
  padding-top: 50px;
  padding-bottom: 20px;
}
.home-events-elt {
  margin-bottom: 10px;
}
.home-events-elt h3 {
  width: 95%;
  font-family: var(--ff-poppins);
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 27px;
  text-align: center;
  color: #ffffff;
  margin-left: auto;
  margin-right: auto;
  padding-bottom: 10px;
}
.home-events-elt-text {
  width: 95%;
  max-width: 300px;
  margin-left: auto;
  margin-right: auto;
  font-family: var(--ff-poppins);
  font-style: normal;
  font-weight: 300;
  font-size: 12px;
  line-height: 18px;
  display: flex;
  text-align: center;
  color: #ffffff;
}
.home-events .slick-prev {
  margin-left: 11px;
}
.home-events .slick-next {
  margin-right: 11px;
}
.home-events-elt-date {
  font-family: var(--ff-poppins);
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  color: #ffffff;
  border: 1px solid #ffffff;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 50px;
  width: 40%;
  padding: 10px;
}
.home-partners {
  text-align: center;
  position: relative;
}
.home-partners-blur {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  filter: blur(30px);
  z-index: -1;
  border-bottom: 5px solid;
}
.home-partners-content {
  height: 150px;
  background: white;
  display: flex;
  flex-flow: column nowrap;
  justify-content: baseline;
}

.home-partner-logo-img {
  margin: auto;
  max-height: 150px;
  max-width: 242px;
  transform: scale(0.5);
  -webkit-filter: grayscale(100%); /* Safari 6.0 - 9.0 */
  filter: grayscale(100%);
  opacity: 0.5;
}
.home-partner-logo-img:hover {
  -webkit-filter: grayscale(0%); /* Safari 6.0 - 9.0 */
  filter: grayscale(0%);
  opacity: 1;
}
.home-questions {
  max-width: 500px;
}

/*
 * CycleFormation
 */
.cycleformation-header {
  display: flex;
  width: 100%;
  height: 250px;
  position: relative;
}
.cycleformation-header img {
  object-fit: cover;
  width: 100%;
  filter: brightness(20%);
  -khtml-user-select: none;
  -o-user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  user-select: none;
}
.cycleformation-header .bottom-left {
  position: absolute;
  bottom: 40px;
  left: 40px;
  margin-right: 40px;
  font-family: var(--ff-poppins);
  font-style: normal;
  font-weight: 500;
  font-size: 30px;
  line-height: 40px;
  color: #f2f2f2;
}
.cycleformation-cycle {
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
}
.cycleformation-cycle.horizontal {
  flex-direction: row;
}
.cycleformation-cycle.vertical {
  flex-direction: column;
}
.cycleformation-cycle.horizontal .cycleformation-cycle-name .before {
  margin-right: 100px;
}
.cycleformation-cycle.horizontal .cycleformation-cycle-name .after {
  margin-left: 100px;
}
.cycleformation-cycle.vertical .cycleformation-cycle-name {
  align-self: flex-start;
  margin-bottom: 20px;
}
.cycleformation-cycle-name h2 {
  font-family: var(--ff-poppins);
  font-style: normal;
  font-weight: normal;
  font-size: 35px;
  line-height: 32px;
}
.cycleformation-cycle-name .black {
  color: #333333;
}
.cycleformation-cycle-name .red {
  color: #eb5757;
}
.cycleformation-cycle-name .blue {
  color: #2d9cdb;
}
.cycleformation-cycle-name h2 span {
  font-weight: bold;
}
.cycleformation-cycle-box {
  position: relative;
  max-width: 800px;
}
.cycleformation-cycle-box-blur {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  filter: blur(10px);
  z-index: -1;
  border: 2px solid;
}
.cycleformation-cycle-box-content {
  padding: 60px 50px 60px 50px;
  background: white;
  font-family: var(--ff-poppins);
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 26px;
  color: #4f5665;
}
.cycleformation-list {
  margin-top: 40px;
}
.cycleformation-list ul {
  padding: 0;
  list-style-type: none;
}
.cycleformation-list ul li {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-top: 20px;
}
.cycleformation-list ul li img {
  margin-right: 12px;
}
.fancy-lines .fancy-elt {
  display: inline-block;
  position: relative;
}
.fancy-lines .before:before {
  content: '';
  position: absolute;
  top: 15%;
  height: 70%;
  border-bottom: 1px solid black;
  border-top: 1px solid black;
  width: 200px;
  right: 100%;
}
.fancy-lines .after:after {
  content: '';
  position: absolute;
  top: 15%;
  height: 70%;
  border-bottom: 1px solid black;
  border-top: 1px solid black;
  width: 200px;
  left: 100%;
}
.fancy-lines .before.black:before,
.fancy-lines .after.black:after {
  border-bottom: 1px solid #333333;
  border-top: 1px solid #333333;
}
.fancy-lines .before.red:before,
.fancy-lines .after.red:after {
  border-bottom: 1px solid #eb5757;
  border-top: 1px solid #eb5757;
}
.fancy-lines .before.blue:before,
.fancy-lines .after.blue:after {
  border-bottom: 1px solid #2d9cdb;
  border-top: 1px solid #2d9cdb;
}

/*
 * Contact
 */
.contact-box {
  max-width: 800px;
  background: #fafafa;
  box-shadow: 0px 20px 20px rgba(0, 0, 0, 0.05);
  margin-left: auto;
  margin-right: auto;
}
.contact-box.desktop {
  padding: 100px 120px 100px 120px;
}
.contact-box.tablet {
  padding: 80px 100px 80px 120px;
}
.contact-box.mobile {
  padding: 40px 40px 40px 40px;
}

.contact-box .text p {
  font-family: var(--ff-poppins);
  font-style: normal;
  font-weight: 300;
  font-size: 15px;
  line-height: 29px;
  color: #333333;
  margin-bottom: 50px;
}

/*
 * Account
 */
.account {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}
.account-profile {
  height: 100%;
  width: 430px;
  margin: 20px;
}
.account-info {
  height: 100%;
  max-width: 500px;
  position: relative;
}
.account-info-blur {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  filter: blur(8px);
  z-index: -1;
  border: 2px solid;
}
.account-info-content {
  padding: 50px 20px 50px 20px;
  background: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.account-info-buttons {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.account-info-buttons button {
  margin-top: 30px;
  margin-right: 20px;
}
.account-profile img,
.account-info img {
  object-fit: cover;
  width: 100%;
  height: 100%;
}
.account-info h3 {
  font-family: var(--ff-poppins);
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 20px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #4f4f4f;
}
.account-info p {
  font-family: var(--ff-poppins);
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 15px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #4f4f4f;
}

/**
 * ----------------------------------------
 * animation blink-1
 * ----------------------------------------
 */
@-webkit-keyframes blink-1 {
  0%,
  50%,
  100% {
    opacity: 1;
  }
  25%,
  75% {
    opacity: 0;
  }
}
@keyframes blink-1 {
  0%,
  50%,
  100% {
    opacity: 1;
  }
  25%,
  75% {
    opacity: 0;
  }
}

/*
 *  Drive Image
 */
.drive-folder-img,
.drive-folder-missing-img {
  width: 60px;
}
.drive-folder-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.drive-folder-vertical {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.drive-folder-container p {
  font-family: var(--ff-poppins);
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #333333;
  text-align: center !important;
}
.drive-folder-disabled {
  cursor: default !important;
}
.drive-folder-disabled:hover {
  text-decoration: none;
}

/*
 *  Student dashboard
 */
.home-paragraph {
  color: #666666;
  margin: 20px;
}
.firstlastname {
  color: black;
  margin-right: 10px;
  background: #a6a6a6;
}

/*
 * Gallery photo
 */
.photo-grid {
  display: grid;
  gap: 1rem;
  grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
  grid-auto-rows: 350px;
}
.photo-grid img {
  width: 400px;
  height: 350px;
  object-fit: contain;
}
.photo {
  margin: auto;
}

@media screen and (min-width: 992px) {
  .photo {
    transition: transform 0.5s;
  }
  .photo:hover {
    transform: scale(1.2);
  }
}

/*
 * F.A.Q
 */
.faq {
  width: 100%;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
}
.faq-list {
  width: 100%;
  max-width: 1200px;
  font-family: var(--ff-poppins);
  font-style: normal;
  font-weight: normal;
  padding-left: 10px;
  padding-right: 10px;
}
.faq .faq-list .faq-item {
  margin-top: 20px;
}
.faq .faq-list .faq-item .faq-question {
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;
  border-radius: 1.5rem;
  background: white;
  filter: drop-shadow(10px 10px 99px rgba(211, 211, 211, 0.161));
  padding-left: 10px;
  padding-right: 10px;
}
.faq .faq-list .faq-item .faq-question-button {
  width: 100%;
  display: flex;
  justify-content: space-between;
  border: none;
  background-color: transparent;
  padding: 0px;
  cursor: pointer;
  padding-top: 18px;
  padding-bottom: 18px;
  color: #4f4f4f;
  font-size: 18px;
  line-height: 31px;
}
.faq .faq-list .faq-item .faq-question-button:hover {
  color: black;
  font-weight: bold;
}
.faq .faq-list .faq-answer {
  padding-right: 17px;
  margin-top: 0;
  margin-bottom: 0;
  height: 0;
  overflow: hidden;
  transition: all 300ms ease-out;
}
.faq .faq-list .faq-item .faq-answer {
  background: rgba(196, 196, 196, 0.05);
  margin-left: 15px;
  margin-right: 15px;
  color: #4f4f4f;
  font-size: 16px;
  line-height: 31px;
}
.faq .faq-list .faq-item .faq-question .faq-icon {
  transition: transform 300ms ease-out;
}
.faq .faq-list .faq-item .faq-question .faq-icon.faq-is-active {
  transform: rotate(90deg);
  transition: transform 300ms ease-out;
}

/*
 * BLOG
 */
.blog-cards {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: flex-start;
  max-width: 1200px;
  margin: 0 auto;
  padding-top: 20px;
}
.blog-card {
  height: 100%;
  min-height: 500px;
  max-width: 400px;
  border-radius: 0%;
  box-shadow: 0px 10px 50px rgba(242, 242, 242, 0.53);
  margin: 20px;
  border-bottom: 1px solid #d9d9d9;
}
.blog-card .blog-card-body {
  margin: 15px 10px 10px 10px;
}
.blog-card-img-container {
  height: 250px;
}
.blog-card img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
  border-radius: 2%;
}
.blog-card h3 {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 31px;
  color: #0a1331;
}
.blog-card .blog-card-extract {
  display: flex;
  flex-direction: column;
}
.blog-card .blog-card-extract p {
  font-family: var(--ff-poppins);
  font-style: normal;
  font-weight: 300;
  font-size: 12px;
  line-height: 24px;
  display: flex;
  align-items: center;
  color: #5b5b5b;
}
.blog-card .blog-card-extract a {
  align-self: center;
}
.blog-cards-btn-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 30px;
}
.article {
  display: flex;
  flex-flow: column nowrap;
}
.article-header-container {
  height: auto;
  position: relative;
  background: #fafafa;
  top: -50px;
  width: 90%;
  box-shadow: 50px 130px 100px rgba(0, 0, 0, 0.145947);
}
.article-header {
  padding: 30px 10px 30px 70px;
}
.article-header h3 {
  font-family: var(--ff-poppins);
  font-style: normal;
  font-weight: bold;
  font-size: 3vw;
  line-height: 4vw;
  color: #0a1331;
}
.article-meta {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  color: #656565;
  margin-top: 10px;
  margin-bottom: 20px;
}
.article-meta span {
  color: #2d9cdb;
}
.article-img-container {
  height: 400px;
  margin: 20px 0px 50px 0px;
  z-index: 2;
}
.article .article-img {
  display: block;
  margin: auto;
  width: 90%;
  height: 100%;
  object-fit: contain;
}
.article-socials {
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  align-items: center;
}
.article-socials span {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 21px;
  display: flex;
  align-items: flex-end;
  color: #656565;
  margin-right: 20px;
  max-width: 100px;
}
.article-body {
  max-width: 900px;
  align-self: center;
  line-height: 25px;
}
@media screen and (max-width: 1200px) {
  .article-body {
    margin: 0px 70px 0px 70px;
  }
}
@media screen and (max-width: 992px) {
  .article-header-container {
    width: 100%;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
  }
}
@media screen and (max-width: 768px) {
  .article-header-container {
    width: 90%;
    left: 5%;
  }
  .article-header {
    padding: 15px;
  }
  .article-img-container {
    height: auto;
    margin: 0px;
  }
  .article-header {
    padding: 15px;
  }
  .article-body {
    margin: 15px;
  }
  .article-header h3 {
    font-size: 22px;
    line-height: 30px;
  }
}

/*
 * Team
 */
.administration {
  border: 1px black;
}
.administration-service {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  margin-top: 50px;
  background: #fafafa;
  box-shadow: 0px 5px 5px rgba(0, 0, 0, 0.05);
  padding-bottom: 10px;
  padding-top: 10px;
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
}
.administration-service h3 {
  font-family: var(--ff-poppins);
  font-style: normal;
  font-weight: bold;
  font-size: 1.9vw;
  color: #0a1331;
}
.employee-cards {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin: 0 auto;
  padding-top: 20px;
}
.employee-cards .employee-card {
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  align-items: center;
  margin: 10px;
  max-width: 560px;
  width: 100%;
}
.employee-cards .employee-card .employee-card-img-container {
  height: 150px;
  width: 150px;
  overflow: hidden;
  border-radius: 50%;
}
.employee-cards .employee-card .employee-card-img-container img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
  border-radius: 2%;
}
.employee-cards .employee-card .employee-card-body {
  height: 100%;
  width: 100%;
  max-width: 390px;
  margin: 10px;
}
.employee-cards .employee-card .employee-card-body h4 {
  font-family: var(--ff-poppins);
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  color: #2d9cdb;
}
.employee-cards .employee-card .employee-card-body h5 {
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  color: #4d4d4d;
}
@media screen and (max-width: 1200px) {
  .administration-service h3 {
    font-size: 25px;
  }
}
@media screen and (max-width: 610px) {
  .employee-cards .employee-card {
    justify-content: center;
  }
  .administration-service h3 {
    font-size: 25px;
  }
}

/*
 * Socials
 */
.socials {
  height: 30px;
}
.footer-socials .socials {
  margin-bottom: 20px;
}
.social-background {
  background: #eb5757;
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  float: left;
  margin-right: 20px;
  margin-bottom: 20px;
}
.social-img {
  width: 20px;
  height: 20px;
}
.social-img img {
  width: 20px;
  height: 20px;
}

/*
* Custom list
*/
.custom-list ul {
  padding: 0;
  list-style-type: none;
}
.custom-list ul li {
  margin-bottom: 20px;
}
.custom-list.black ul li::before,
.custom-list.blue ul li::before,
.custom-list.red ul li::before {
  margin-right: 10px;
}
.custom-list.black ul li::before {
  content: url('./files/visitor/black-check.svg');
}
.custom-list.blue ul li::before {
  content: url('./files/visitor/blue-check.svg');
}
.custom-list.red ul li::before {
  content: url('./files/visitor/red-check.svg');
}
.custom-list.black.last-child-red ul li:last-child::before {
  content: url('./files/visitor/red-check.svg');
}

.MissingInfoButton {
  border: 0px;
}

.RegisteringStepsList__title {
  font-family: Poppins;
  font-size: 16px;
  font-weight: 800;
  text-align: center;
}
.RegisteringStepsList__content {
  font-family: Poppins;
  font-size: 12px;
  font-weight: 500px;
  text-align: justify;
  margin: 10px;
}
.RegisteringStepsList__content span {
  color: #026599;
  font-weight: 700;
}
.RegisteringStepsList button {
  margin-bottom: 10px;
}

.Video__drive {
  width: 73%;
  height: 500px;
  margin-left: 48px;
  background: url('./files/loadingPercentage.gif');
}

/* Result */
@media screen and (max-width: 481px) {
  .Result section {
    display: flex !important;
    flex-direction: column;
  }

  .Result img {
    align-self: center;
  }
}

/* select */

.Input select {
  width: 100% ; 
  padding: 10px !important;
  color: #bebebe !important;
  border: 1px solid #ccc !important;
}

select {
  width: 100%; 
  padding: 10px !important; 
  border: 1px solid #ccc !important;
  
}
