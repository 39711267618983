.Alumni {
  display: flex;
  flex-direction: column;
  padding: 0 3em;
}

.Alumni > * {
  margin: 1em 0;
}

.Alumni__Paragraph {
  font-size: 1em;
  text-align: center;
}

.Alumni__form {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1em 1em;
}

.Alumni__Details {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1em 1em;
}

.Alumni__form input {
  width: 100%;
}

.Alumni__form input,
select {
  background: #ffffff;
  border: 1px solid #d4d4d4;
  box-sizing: border-box;
  padding: 10px 20px 10px 20px;
  margin-bottom: 20px;
  width: 100%;
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 29px;
}
/* Placeholders */
.Alumni__form input::placeholder {
  color: #bdbdbd;
}

.Alumni__form input[type='submit'] {
  width: 50%;
  margin: auto;
  grid-column-start: 1;
  grid-column-end: 3;
}

.recaptcha__div {
  margin: auto;
  grid-column-start: 1;
  grid-column-end: 3;
}

@media screen and (max-width: 768px) {
  .Alumni__form {
    display: flex;
    flex-direction: column;
  }

  .Alumni__form input[type='submit'] {
    width: 50%;
    margin: auto;
  }
}
