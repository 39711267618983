.Loan {
    box-shadow: 0 0.5px 0 0 #ffffff inset, 0 1px 2px 0 #b3b3b3;
    border-radius: 0.4em;
    margin-bottom: 1em;
    display: flex;
    justify-content: space-around;
    height: 6em;
  }
  
  .Loan:hover {
    background-color: #e6e6e6;
  }
  
  .Loan__button-section {
    align-self: center;
  }
  
  .Loan__button-section > * {
    margin: 0 0.4em;
  }
  
  .Loan__text-section {
    display: flex;
    flex-direction: column;
    flex-basis: 100%;
    flex: 1;
    flex-wrap: wrap;
  }
  