/* Basics */
.Form--fancy {
  display: flex;
  flex-direction: column;
}

.Form input[type='password'],
.Form input[type='text'],
.Form select,
.Form label,
.Form .tox-tinymce {
  background: #ffffff;
  border: 1px solid #d4d4d4;
  box-sizing: border-box;
  padding: 10px 20px 10px 20px;
  margin-bottom: 20px;
  width: 100%;
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 29px;
}
/* Placeholders */
.Form input[type='text']::placeholder,
.Form input[type='password']::placeholder,
.Form textarea::placeholder {
  color: #bdbdbd;
}
/* Displayed image */
.Form .medium-img {
  max-width: 350px;
  margin-bottom: 20px;
  border: 1px solid #d4d4d4;
  box-sizing: border-box;
  padding: 5px;
}
/* Input File */
.Form input[type='file'] {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}
.Form label.upload {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 50%;
  cursor: pointer;
  background: rgba(242, 242, 242, 0.18);
  border: 0.5px solid #d4d4d4;
}
.Form label.upload span {
  font-family: var(--ff-poppins);
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 19px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #bdbdbd;
  padding-top: 10px;
}
/* Errors */
.Form .error {
  color: red;
}
/* Paragraphs */
.Form p {
  font-family: var(--ff-poppins);
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 19px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #4f4f4f;
  padding-top: 10px;
}

/* PDF and Video form */
.PDFForm,
.VideoForm {
  padding: 1rem;
  display: grid;
  grid-gap: 0.7rem;
  grid-template-areas:
    'duration description'
    'main main'
    'submit submit';
  align-items: center;
  justify-items: center;
}

.PDFForm label.upload {
  width: 100%;
}

.main,
.videoUrl {
  width: 100%;
  grid-area: main;
}

.description {
  width: 100%;
  grid-area: description;
}

.PDFForm input[type='submit'],
.VideoForm input[type='submit'] {
  grid-area: submit;
}

/* Editor form */
.EditionForm {
  padding: 1rem;
  display: grid;
  grid-gap: 0.7rem;
  grid-template-areas:
    'duration description'
    'editor editor'
    'submit submit';
  align-items: center;
  justify-items: center;
}

.EditionForm .tox-tinymce {
  width: 100%;
  grid-area: editor;
}

.EditionForm input[type='submit'] {
  grid-area: submit;
}

/* CourseForm */
.CourseForm__profsAndFields {
  display: flex;
}
.CourseForm__profsAndFields > * {
  width: 50%;
}

/* QuizForm */
.QuizStepForm__header {
  display: flex;
  flex-direction: row;
}
.QuizStepForm__header input {
  border: 1px solid #b7aaaa40;
  border-radius: 4px;
  box-sizing: border-box;
  margin-bottom: 20px;
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 29px;
  height: 41px;
  width: 170px;
}
.QuizStepFormHeader__duration {
  margin-left: auto;
  background: #e0e0e01a;
  text-align: center;
  width: 100%;
  grid-area: duration;
}
.QuizStepFormHeader__minScore {
  margin-right: 88px;
  background: #eb57570d;
  color: #6d0808;
  text-align: center;
}
.QuestionComponent {
  border: 1px solid #e0e0e0;
  border-radius: 2px;
  margin: auto;
  width: 747px;
}
.QuestionComponent__delete {
  display: block;
  margin-top: 10px;
  margin-left: auto;
  margin-right: 110px;
}

.QuestionComponent__label {
  height: 80px;
  width: 524px;
  margin: 10px auto -30px;
  padding: 15px;
  display: block;
  background: #f2f2f280;
  border: 0px;
}
.AnswerComponent {
  display: flex;
  flex-direction: row;
  margin-left: 112px;
  width: 500px;
  margin-top: 18px;
}

.AnswerComponent__answer {
  border: 0px;
  border-bottom: 1px solid #bdbdbd;
  height: 75px;
  width: 454px;
}

.AnswerComponent__correct span::after {
  content: url('../files/starFullOrange.svg');
}

.AnswerComponent__incorrect span::after {
  content: url('../files/starEmpty.svg');
}

.AnswerComponent__puce::before {
  content: url('../files/answerPuce.svg');
}
.AnswerComponent__puce {
  margin: 24px 10px auto 20px;
}
.AnswerComponent__AddAlternative {
  background: #eb575726;
  display: block;
  margin-top: 43px;
  margin-bottom: 50px;
  margin-left: auto;
  margin-right: 110px;
  height: 41.108131408691406px;
  width: 206.7270050048828px;
}

.AddNewQuestion {
  margin: auto;
  height: 190px;
  width: 747px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 15px;
  border: 1px solid #e0e0e0;
  box-shadow: 0px 2px 2px 0px #0000001a;
}

.QuizStepFormBody__saveBtn {
  margin: 53px 394px;
  height: 42px;
  width: 130px;
  border-radius: 0px;
}

.AnswerComponent__star {
  margin-top: -25px;
}

/* VIDEO FORM */
.videoUrl input {
  height: 50px;
  border: 1px solid #d4d4d4;
  width: 74%;
}
.videoUrl {
  margin-left: 22%;
}

.duration input {
  border: 1px solid #d4d4d4;
  width: 198px;
  margin-left: -25%;
  height: 100px;
}

.description textarea {
  border: 1px solid #d4d4d4;
  height: 87px;
  width: 107%;
}

.description {
  margin-left: -322px;
}

.VideoForm__link a {
  font-weight: 500;
  color: blue;
  font-style: italic;
  text-decoration: underline;
}

.VideoForm__link {
  margin-top: 25px;
  height: 151px;
  margin-left: 10%;
  width: 600px;
  border: 1px solid aliceblue;
}

.Link__puce {
  background: url('../files/videoPuce.svg');
  background-repeat: no-repeat;
  background-position: center;
  background-color: aliceblue;
}

.Link__content div {
  margin-top: 30px;
}

/*
 * FORMS
 */
/* Basics */
.fancy-form {
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  width: 100%;
}
.fancy-form input[type='text'],
.fancy-form input[type='number'],
.fancy-form input[type='password'],
.fancy-form textarea,
.fancy-form select,
.fancy-form label,
.fancy-form .tox-tinymce {
  background: #ffffff;
  border: 1px solid #d4d4d4;
  box-sizing: border-box;
  padding: 10px 20px 10px 20px;
  margin-bottom: 20px;
  width: 100%;
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 29px;
}

/**********************************************
PhoneInput
/**********************************************/

.fancy-form .PhoneInputInput {
  background: #ffffff;
  border: 1px solid #d4d4d4;
  box-sizing: border-box;
  padding: 10px 20px 10px 20px;
  margin-bottom: 20px;
  width: 100%;
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 29px;
}

.fancy-form .PhoneInputCountryIcon {
  margin-bottom: 15px;
  scale: 1.2;
}

/**********************************************
PhoneInput
/**********************************************

/* Placeholders */
.fancy-form input[type='text']::placeholder,
.fancy-form input[type='password']::placeholder,
.fancy-form textarea::placeholder {
  color: #bdbdbd;
}
/* Displayed image */
.fancy-form .medium-img {
  max-width: 350px;
  margin-bottom: 20px;
  border: 1px solid #d4d4d4;
  box-sizing: border-box;
  padding: 5px;
}
/* Input File */
.fancy-form input[type='file'] {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}
.fancy-form label.upload {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 50%;
  cursor: pointer;
  background: rgba(242, 242, 242, 0.18);
  border: 0.5px solid #d4d4d4;
}
.fancy-form label.upload span {
  font-family: var(--ff-poppins);
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 19px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #bdbdbd;
  padding-top: 10px;
}
/* Errors */
.fancy-form .error {
  color: red;
}
/* Paragraphs */
.fancy-form p {
  font-family: var(--ff-poppins);
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 19px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #4f4f4f;
  padding-top: 10px;
}

/*
 * Login
 */
.form-wrapper {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  min-height: 100%;
  padding-top: 50px;
}

/**********************************************
PhoneInput
/**********************************************/
.form-wrapper .PhoneInput {
  background-color: #f6f6f6;
  margin: 5px;
  padding: 0px 5px;
  transition: all 0.5s ease-in-out;
  border-radius: 5px 5px 5px 5px;
  width: 100%;
  transition: all 0.5s ease-in-out;
}

.form-wrapper .PhoneInputInput {
  padding: 15px 32px;
  background-color: #f6f6f6;
  font-family: var(--ff-poppins);
  line-height: 30px;
  letter-spacing: 0.454545px;
  border: 2px solid #f6f6f6;
  margin-bottom: 0;
}

.form-wrapper .PhoneInputCountryIcon {
  margin-bottom: 0;
  scale: 1;
}
/**********************************************
PhoneInput
/**********************************************/

.form-wrapper .instruction {
  margin: 30px;
}
.form-wrapper .logo {
  margin-top: 20px;
  margin-bottom: 20px;
}
.form-wrapper {
  text-align: center;
}
.form-wrapper #formFooter {
  background-color: #f6f6f6;
  border-top: 1px solid #dce8f1;
  padding: 25px;
  text-align: center;
  -webkit-border-radius: 0 0 10px 10px;
  border-radius: 0 0 10px 10px;
}
.form-wrapper a {
  font-family: var(--ff-poppins);
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 121.5%;
  letter-spacing: 1px;
  color: #2d9cdb;
}
.form-wrapper h2.inactive {
  color: #cccccc;
}
.form-wrapper h2.active {
  color: #0d0d0d;
  border-bottom: 2px solid #5fbae9;
}
.form-wrapper #formContent input[type='button'],
.form-wrapper #formContent input[type='submit'],
.form-wrapper #formContent [type='reset'],
.form-wrapper #formContent select {
  border: none;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  text-transform: uppercase;
  font-size: 13px;
  font-family: var(--ff-poppins);
  font-style: normal;
  line-height: 24px;
  align-items: center;
  border-radius: 5px 5px 5px 5px;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
.form-wrapper #formContent input[type='submit'],
.form-wrapper #formContent [type='reset'] {
  background-color: #026599;
  color: white;
}
.form-wrapper #formContent input[type='submit'] {
  margin-bottom: 20px;
}
.form-wrapper .error {
  display: inline-block;
}
.form-wrapper #formContent input[type='button']:hover,
.form-wrapper #formContent input[type='submit']:hover,
.form-wrapper #formContent input[type='reset']:hover {
  background-color: #39ace7;
}
.form-wrapper #formContent input[type='button']:active,
.form-wrapper #formContent input[type='submit']:active,
.form-wrapper #formContent input[type='reset']:active {
  -moz-transform: scale(0.95);
  -webkit-transform: scale(0.95);
  -o-transform: scale(0.95);
  -ms-transform: scale(0.95);
  transform: scale(0.95);
}
.form-wrapper #formContent input[type='text'],
.form-wrapper #formContent input[type='password'],
.form-wrapper #formContent select {
  background-color: #f6f6f6;
  border: none;
  color: #0d0d0d;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 5px;
  font-family: var(--ff-poppins);
  font-style: normal;
  font-weight: 300;
  line-height: 30px;
  letter-spacing: 0.454545px;
  border: 2px solid #f6f6f6;
  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -ms-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
  -webkit-border-radius: 5px 5px 5px 5px;
  border-radius: 5px 5px 5px 5px;
}
.form-wrapper #formContent input[type='text']:disabled {
  background-color: #f6f6f6;
  color: #a9a9a9;
}
.form-wrapper #formContent input[type='text']:focus {
  background-color: #fff;
  border-bottom: 2px solid #5fbae9;
}
.form-wrapper #formContent input[type='text']:placeholder {
  color: #cccccc;
}
.form-wrapper #formContent input[type='submit'] {
  margin-top: 30px;
}
.form-wrapper #formContent select {
  text-align: center;
  text-align-last: center;
}
.form-wrapper #formContent option {
  text-align: left;
  /* reset to left*/
}
@media screen and (min-width: 768px) {
  .form-wrapper {
    padding: 20px;
  }
  .form-wrapper #formContent {
    -webkit-border-radius: 10px 10px 10px 10px;
    border-radius: 10px 10px 10px 10px;
    background: #fff;
    padding: 30px;
    width: 90%;
    max-width: 450px;
    position: relative;
    padding: 0px;
    -webkit-box-shadow: 0 30px 60px 0 rgba(0, 0, 0, 0.3);
    box-shadow: 0 30px 60px 0 rgba(0, 0, 0, 0.3);
    text-align: center;
  }
  .form-wrapper #formContent input[type='text'],
  .form-wrapper #formContent input[type='password'],
  .form-wrapper #formContent select,
  .form-wrapper .PhoneInput {
    width: 85%;
  }
}
.recaptcha {
  margin-top: 10px;
  text-align: center;
  display: inline-block;
  margin-bottom: -10px;
}
