.Chapters {
  display: flex;
  justify-content: space-around;
}

.Chapters--noChapters {
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  min-height: 55vh;
  padding: 0rem 20%;
  background: rgba(235, 87, 87, 0.04);
}

.Chapters__list {
  width: 150%;
  margin: 1rem;
}

.Chapter__item {
  display: flex;
  align-items: center;
  margin: 0.5rem 0;
}

.Chapter__item:hover {
  background: rgba(224, 224, 224, 0.2);
  cursor: pointer;
}

.Chapter__item > * {
  margin: 0 0.7rem;
}

.Chapter__title {
  font-family: var(--ff-poppins);
  letter-spacing: 0.1rem;
  font-weight: 600;
  min-width: 6.5rem;
}
.Chapter__section {
  flex-grow: 1;
}

.Chapter__header {
  display: flex;
  justify-content: space-evenly;
  align-items: center;

  background: rgba(242, 242, 242, 0.67);
}

.Chapter__header > * {
  margin: 2rem 1rem;
}

.Chapters__select {
  display: block;
  margin: 0.3rem 1rem 0.3rem auto;
  min-width: 10rem;
}

.Chapter {
  box-shadow: 0px 5px 20px 0px rgba(0, 0, 0, 0.1459);
}

.Chapter__main--noSteps {
  background: url('../files/backgroundChapter.png') no-repeat center;
  min-height: calc(80vh - 2rem);
}

.ChapterForm {
  width: 90%;
  margin: auto;
}
