.Internship {
  display: flex;
  flex-direction: column;
  padding: 0 3em;
}

.Internship > * {
  margin: 1em 0;
}

.Internship__Paragraph {
  font-size: 1em;
  text-align: center;
}

.Internship__form {
  display: flex;
  flex-direction: column;
}

.Internship .form-group {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1em 1em;
}

.Internship__Details {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1em 1em;
}

.Internship__form input {
  width: 100%;
}

.Internship__form input,
select,
textarea {
  background: #ffffff;
  border: 1px solid #d4d4d4;
  box-sizing: border-box;
  padding: 10px 20px 10px 20px;
  margin-bottom: 20px;
  width: 100%;
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 29px;
}
/* Placeholders */
.Internship__form input::placeholder {
  color: #bdbdbd;
}

.Internship__form input[type='submit'],
.serialNum,
textarea {
  width: 50%;
  margin: auto;
  grid-column-start: 1;
  grid-column-end: 3;
}
.Internship__form textarea,
.serialNum {
  width: 100%;
}

@media screen and (max-width: 768px) {
  .Internship .form-group {
    display: flex;
    flex-direction: column;
  }

  .Internship__form input[type='submit'] {
    width: 50%;
    margin: auto;
  }
}
