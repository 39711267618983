/* Statistics */
.Statistics__List {
  margin: 0px 0px 5px 0px;
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  align-items: center;
}
.Statistics__Item {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 130px;
  background: #f5f5f5;
  padding: 0px 10px;
  margin: 1px 3px;
}
.Statistic__count {
  font-family: 'Poppins';
  font-weight: 900;
  font-size: 25px;
  line-height: 96px;
  color: #2d9cdb;
  margin-right: 5px;
}
.Statistic__label {
  font-family: 'poppins';
  font-size: 13px;
  font-style: normal;
  letter-spacing: 0em;
  color: #333333;
}

.Statistic__details {
  font-family: 'inherent';
  font-size: 'inherent';
  font-style: 'inherent';
  letter-spacing: 'inherent';
  color: 'inherent';
  position: absolute;
  right: 5%;
}
