.Messenger {
  height: 100vh;
  display: flex;
}

.ChatMenu {
  flex: 1;
}

.ChatMenu__top {
  display: flex;
  justify-content: space-between;
  align-items: center;

  font-family: var(--ff-poppins);
  font-weight: 400;
  width: 100%;
  padding: 0.3em 0;
  border: none;
  border-bottom: 1px solid #eaedf3;
}

.ChatMenu__button {
  background: #56ccf226;
  color: #2d9cdb;
  padding: 0.5em;
  border: none;
  border-radius: 0.35em;
}

.ChatBox {
  flex: 2;
}

.ChatBox__top {
  position: relative;
  height: 100%;
  overflow-y: scroll;
  padding-right: 0.5em;
}

.ChatBox__header {
  position: sticky;
  background: #ebf8ff;
  width: 100%;
  height: 3em;

  display: flex;
  align-items: center;
  color: #323e4a;
}

.ChatBox__bottom {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}

.ChatBox__wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
}

.ChatMenu__wrapper,
.ChatBox__wrapper {
  padding: 1vmin;
  height: 100%;
}

.ChatBox--noConversationText {
  position: absolute;
  top: 10%;
  font-size: 50px;
  color: rgb(224, 220, 220);
  cursor: default;
}

.ChatMessage__input {
  border: none;
  width: 75%;
}

/* Conversation */
.Conversation {
  display: flex;
  align-items: center;
  padding: 0.8em;
  cursor: pointer;
  margin-top: 20px;
}

.Conversation:hover {
  background: #ebf8ff;
}

.Conversation__img {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  object-fit: cover;
  margin-right: 20px;
}

.Conversation__userName {
  color: #323e4a;
}

.Conversation__texts {
  width: 100%;
  color: #828282;
}

.Conversation__upTexts {
  display: flex;
  justify-content: space-between;
}

/* Messenger */
.Message {
  display: flex;
  flex-direction: column;
  margin-top: 1.3em;
}

.Message__body {
  width: 60%;
}

.Message--own .Message__body {
  align-self: flex-end;
}

.Message__top {
  display: flex;
  justify-content: space-between;
}

.Message__text {
  padding: 0.8em;
  border-radius: 0.4em;
  background: #2d9cdb;
  color: white;
}

.Message--own .Message__text {
  background: #eaeff4;
  color: #51606d;
}

.FilePreview {
  background-color: #eaeff4;
  width: 60%;
  padding: 0.2em 0.5em;
  border-radius: 0.5rem;
}

.FilePreview li {
  display: flex;
  justify-content: space-between;
  list-style: url(../files/attachement.svg);
  list-style-position: inside;
}

.FilePreview__button {
  border: none;
  text-decoration: none;
}

.FilePreview__button:hover {
  text-decoration: underline;
}
