.NotFound {
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: center;
  height: 100vh;
}
.NotFound__section {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.NotFound__section--left {
  align-items: center;
}
.NotFound__section--right {
  align-items: flex-start;
}
.NotFound__title {
  font-style: normal;
  font-weight: 600;
  font-size: 30px;
  line-height: 24px;
  color: #e4e4e4;
  padding-bottom: 30px;
}
.NotFound__logo {
  max-width: 200px;
}
.NotFound__paragraph {
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 24px;
  color: #000000;
  max-width: 300px;
  padding-top: 10px;
}
.NotFound__link {
  max-width: 100px;
}

@media screen and (max-width: 768px) {
  .NotFound__section {
    align-items: center;
  }
  .NotFound {
    flex-flow: column wrap;
  }
  .NotFound__paragraph {
    text-align: center;
  }
}
