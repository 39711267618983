.Formation__ButtonModal {
  display: flex;
  justify-content: flex-end;
}

.Formation__ButtonModal > * {
  margin: auto 0.5rem;
}

.Formation__main {
  margin-top: 1.5rem;

  display: flex;
  justify-content: space-around;
}

.Formation__section {
  background: #fafafa;
  box-shadow: 0 20px 20px rgba(0, 0, 0, 0.05);
  width: 50%;
  height: auto;

  margin: 0 1rem;
}

.Formation__title {
  font-family: var(--ff-poppins);
  font-size: 1.17rem;
  font-weight: bold;

  margin: 0;
  padding: 1.5rem 1.5rem;
  color: rgba(79, 79, 79, 1);
}

.Formation__title::after {
  content: '';
  display: block;
  width: 85%;
  margin-top: 1rem;
  border: 0.5px solid rgb(226, 226, 226);
}

.Formation__paragraph {
  font-family: var(--ff-poppins);

  margin: 1rem;
}

.Formation__row {
  display: flex;
  justify-content: space-between;
  align-items: center;

  padding: 0.8rem 2rem;
}

.Formation__row--active {
  background: rgba(236, 236, 255, 0.9);
}

.Formation__row:hover {
  background: rgba(236, 236, 255, 0.9);
}

.Formation__rowText {
  cursor: pointer;
  margin-right: auto;
  color: rgba(130, 130, 130, 1);
}

.Formation__rowText--field-fontSize {
  font-size: 1.1rem;
}
