.Navbar {
  width: 100%;
  padding: 0.35rem 1rem;
  position: fixed;
  top: 0;
  z-index: 30;

  display: flex;
  justify-content: space-evenly;
  align-items: center;

  background-color: #ffffff;
  box-shadow: 0 0.5px 0 0 #ffffff inset, 0 1px 2px 0 #b3b3b3;
}

.Navbar__logo {
  max-width: 200px;
}
/* Private Navbar*/
.Navbar__account {
  display: flex;
  align-items: center;
}

.Navbar__input {
  flex-grow: 0.95;
  margin: 0 1rem;
}

.Navbar__separator {
  border-left: 3px solid #eff4f7;
  height: 4rem;
  margin: 0 0.2rem;
}

.Navbar__profile {
  margin: 0 0.4rem;

  display: flex;
  justify-content: center;
  align-items: center;
}

.Navbar__pp {
  width: 50px;
  height: 50px;

  border-radius: 50% 50%;
}

.Navbar__user {
  font-family: var(--ff-poppins);
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 21px;
  text-align: right;
  letter-spacing: 0.01em;
  color: #000000;
}

.Navbar__userType {
  font-family: var(--ff-poppins);
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 18px;
  text-align: right;
  letter-spacing: 0.01em;
  color: #a6acbe;
  float: right;
}

.Navbar__btn {
  cursor: pointer;
  margin: 0 0.1rem;
  padding: 0 0.15rem;
}

.Navbar__btn:hover {
  background-color: #eff4f7;
}
/* Public Navbar*/
.Navbar__nav {
  flex-grow: 1;

  padding: 0 3.5rem;
  display: flex;
  justify-content: space-evenly;
}

.Navbar__link {
  font-family: var(--ff-poppins);
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: #4f5665;
}

.Navbar__link:hover {
  color: #4f5665;
}

.Navbar__link--login {
  color: #f53855;
  border: 1px solid #f53855;
  padding-left: 10px;
  padding-right: 10px;
}

.Navbar__link--login:hover {
  color: #f53855;
}

.Navbar__link.selected {
  color: #eb5757;
}

@media screen and (max-width: 992px) {
  .Navbar {
    display: flex;
    justify-content: flex-start;
  }
  .Navbar__nav,
  .Navbar__account,
  .Navbar__input {
    display: none;
  }

  .Navbar--private {
    display: flex;
    justify-content: center;
  }
}
